import React, { useEffect } from 'react';
import { InfoBlock, SmsNotificationCoditions } from '@components/common';
import Text, { Header } from '@components/ui/Typography';
import InputMask from 'react-input-mask';
import { useFormikContext } from 'formik';
import { Field, Checkbox } from '@components/ui/form';
import {
  addictionalServicesSelector,
  AddictionalServiceTypes,
} from '@modules/booking';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUserDataState } from '@modules/user';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Hooks } from '@utils';
import { FormikFields } from './PassengersBlock/types';
import ru from 'react-phone-input-2/lang/ru.json';
import { postSessionLogHandler } from '@modules/logs/duck';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';
import { UserActionTypes } from '@modules/logs/types';

interface Props {
  type: 'train' | 'avia' | 'bus';
  mobileSubmitCount?: number;
}

const Body = styled.div`
  margin-top: 15px;
  ${({ theme }) => `
      ${theme.max('767px')} {
        margin-top: 10px;
      }
  `}
`;

const Description = styled(Text)`
  margin-bottom: 32px;
  ${({ theme }) => `
      ${theme.max('767px')} {
        color: #737373;
      }
  `}
`;

const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  @media (max-width: 1024px) {
    row-gap: 20px;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
  ${({ theme }) => `

    ${theme.max('767px')} {
      margin-bottom: 0;
      grid-template-columns: 1fr;
    }
`}
`;

const Wrapper = styled(InfoBlock)`
  padding: 24px;
  @media (max-width: 767px) {
    padding: 14px;
    margin-top: 30px;
    width: 100%;
  }
  @media (min-width: 767px) {
    margin-top: 30px;
  }
`;

const NotificationWrapper = styled.div`
  background: #edf1fe;
  border: 1px solid #4872f2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 19px;
  margin-top: 20px;
  margin-bottom: 20px;

  & > span {
    display: block;
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin: 14px 0 14px 0;
  }
`;

const NotificationText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }
`;

const NewField = styled(Field)`
  &:disabled {
    background: #f1f1f2;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: #737373;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 5px;
`;

const Contacts: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { handleChange, handleBlur, errors, values, touched, submitCount } =
    useFormikContext<{
      name: string;
      email: string;
      phone: string;
      phoneTwo: string;
      nameTwo: string;
      sameContactInfo: boolean;
      needShareContacts: boolean;
      submitCount: number;
    }>();
  const { isMobile } = Hooks.useMediaSizes();

  const userData = useSelector(getUserDataState);

  useEffect(() => {
    const isEmailDigitsOnly = (email: string): boolean => {
      const localPart = email.split('@')[0];
      return /^\d+$/.test(localPart);
    };

    values.phone = values?.phone ?? userData?.phone ?? '';
    // особые условия для пользователей Банка Т
    // https://jira-new.fstravel.com/browse/TTE-11144
    if (userData?.email && isEmailDigitsOnly(userData.email)) {
      values.email = values?.email ?? '';
    }
    if (userData?.email && !isEmailDigitsOnly(userData?.email)) {
      values.email = values?.email ?? userData.email ?? '';
    }
    values.name = values?.name ?? userData?.name ?? '';
  }, [userData, values.phone, values.email, values.name]);

  useEffect(() => {
    postLogging('name', values.name);
  }, [values.name]);

  useEffect(() => {
    postLogging('phone', values.phone);
  }, [values.phone]);

  useEffect(() => {
    postLogging('email', values.email);
  }, [values.email]);

  const items = useSelector(addictionalServicesSelector);

  const isSmsService = items.find(
    (x) => x.type === AddictionalServiceTypes.SMS_Service && x.checked,
  );

  const [popup, setPopup] = React.useState(false);

  const formik = useFormikContext<FormikFields>();

  const handlePhoneChange = (val: any) => {
    formik.setFieldValue(`phone`, val);
    return;
  };

  const postLogging = (field: string, value: string) => {
    let screen = '';
    let actionId = '';
    if (field === 'name') {
      actionId = LOGGING_ACTION_IDS.CONTACTS.CONTACTS_NAME_INPUT;
    }
    if (field === 'phone') {
      actionId = LOGGING_ACTION_IDS.CONTACTS.CONTACTS_PHONE_INPUT;
    }
    if (field === 'email') {
      actionId = LOGGING_ACTION_IDS.CONTACTS.CONTACTS_EMAIL_INPUT;
    }
    if (props.type == 'avia') {
      screen = LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME;
    }
    if (props.type == 'train') {
      screen = LOGGING_ACTION_IDS.TRAIN_BOOKING.NAME;
    }
    if (props.type == 'bus') {
      screen = LOGGING_ACTION_IDS.BUS_BOOKING_FIRST_STEP.NAME;
    }
    dispatch(
      postSessionLogHandler({
        eventType: UserActionTypes['Change'],
        actionId: actionId,
        screen: screen,
        value: value,
      }),
    );
  };

  return (
    <Wrapper id="contacts">
      <Header size="h4">Контактная информация</Header>
      <Body>
        <Description color="black" size="normal">
          Мы отправим билет на почту, вышлем уведомления об изменениях в рейсе
          или в случае других экстренных ситуаций
        </Description>
        <Content id="contacts">
          <Field
            data-cy="contactName"
            name="name"
            style={{ width: '100%' }}
            label="Контактное имя"
            error={errors.name && touched.name ? errors.name : undefined}
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            autoComplete="any-random-string-20"
          />
          <Column>
            <Label>Телефон</Label>
            <PhoneInput
              localization={ru}
              countryCodeEditable={false}
              onChange={handlePhoneChange}
              value={values.phone}
              specialLabel={''}
              enableSearch={true}
              country={'ru'}
              searchPlaceholder="Поиск"
              inputStyle={{
                height: '40px',
                width: !isMobile ? '230px' : '100%',
              }}
              containerStyle={{ height: '40px' }}
              inputProps={{
                name: 'phone',
              }}
            />
            {!isMobile && (
              <>
                {errors.phone && submitCount > 0 && (
                  <Label
                    style={{ color: '#F25F61', fontSize: 13, marginTop: 3 }}
                  >
                    {errors.phone}
                  </Label>
                )}
              </>
            )}
            {isMobile && props.mobileSubmitCount ? (
              <>
                {errors.phone && props.mobileSubmitCount > 0 && (
                  <Label
                    style={{ color: '#F25F61', fontSize: 13, marginTop: 3 }}
                  >
                    {errors.phone}
                  </Label>
                )}
              </>
            ) : null}
          </Column>

          <Field
            data-cy="contactEmail"
            label="Электронная почта"
            name="email"
            autoComplete="any-random-string"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : undefined}
            style={{ width: '100%' }}
          />
        </Content>
        {(props.type === 'train' || props.type === 'bus') && (
          <Checkbox
            type="squar"
            label="Сообщать всем пассажирам (добавим ввод контакта каждому пассажиру)"
            name="needShareContacts"
            checked={values.needShareContacts}
            onChange={handleChange}
          />
        )}
      </Body>
      {isSmsService && (
        <>
          <NotificationWrapper>
            <NotificationText>
              Вы добавили услугу <strong>SMS-информирование</strong>.{' '}
              Уведомления по заказу автоматически будут присылаться на указанный
              номер телефона.
            </NotificationText>
            <NotificationText>
              Если вам необходимо получать уведомления на другой номер, укажите
              его в поле ниже.
            </NotificationText>
          </NotificationWrapper>
          <Content>
            <NewField
              name="nameTwo"
              style={{ width: '100%' }}
              label="Имя"
              error={
                errors.nameTwo && touched.nameTwo ? errors.nameTwo : undefined
              }
              onChange={handleChange}
              disabled={values.sameContactInfo}
              value={!values.sameContactInfo ? values.nameTwo : values.name}
              onBlur={handleBlur}
            />
            <InputMask
              mask="+7 (999) 999-99-99"
              onChange={handleChange}
              autoComplete="off"
              value={!values.sameContactInfo ? values.phoneTwo : values.phone}
              disabled={values.sameContactInfo}
              onBlur={handleBlur}
              maskChar={null}
            >
              <NewField
                label="Телефон"
                name="phoneTwo"
                error={
                  errors.phoneTwo && touched.phoneTwo
                    ? errors.phoneTwo
                    : undefined
                }
                style={{ width: '100%' }}
              />
            </InputMask>
          </Content>
          <div>
            <Checkbox
              type="squar"
              label="Использовать те же контактные данные"
              name="sameContactInfo"
              checked={values.sameContactInfo}
              onChange={handleChange}
            />
          </div>
        </>
      )}
      <SmsNotificationCoditions
        open={popup}
        onClose={() => {
          setPopup(false);
        }}
      />
    </Wrapper>
  );
};

export default Contacts;
