import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { FlightSegmentPropsTemp, Separator } from '@components/common';
import { FLIGHT_TYPES, TicketItemProps } from '../types';
import SegmentInBooking from '../SegmentInBooking';
import { useMediaQuery } from 'react-responsive';
import { LowcosterPopup } from '@components/common/OtherNotificationsPanels';
import { formatDirectionName } from '@app/utils/helpers';
import { getSelectedFare } from '@app/modules/booking';
import { useSelector } from 'react-redux';

const Container = styled.div``;

const Content = styled.div`
  padding: 0 24px;
  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme }) => `
    ${theme.max('767px')} {
      padding: 0 14px;
      padding-bottom: 0;
    }
  `}
`;

const SegmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
`;

const StyledSeparator = styled(Separator)`
  margin-top: 0;
`;

const MobileSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 14px 0;

  @media (min-width: 768px) {
    display: none;
  }
`;

const FlightItem: React.FC<TicketItemProps> = React.memo(
  ({
    separator,
    segments,
    stops,
    flightIndex,
    baggageDescr,
    flight_Type,
    amount,
    ...props
  }) => {
    const startSegment = segments[0];
    const endSegment = segments[segments.length - 1];
    const fromCityName = startSegment.from.city;

    const toCityName = endSegment.to.city;
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [updateSegments, setUpdateSegments] = React.useState(segments);

    if (
      window.location.pathname.includes('/booking') &&
      !window.location.pathname.includes('/bookingDetails')
    ) {
      const fare = useSelector(getSelectedFare);
      React.useEffect(() => {
        setUpdateSegments([...segments]);
      }, [fare]);
    }

    const detailProps: FlightSegmentPropsTemp = {
      baggageDescr: baggageDescr,
      from: {
        ...segments[0],
        airport: segments[0].from.airport,
        code: segments[0].from.code,
        date: `${segments[0].fromDate} ${segments[0].fromTime}`,
        city: segments[0].from.city,
        terminal: segments[0].from.terminal,
        country: segments[0].from.country,
      },
      to: {
        ...endSegment.to,
        airport: endSegment.to.airport,
        code: endSegment.to.code,
        date: `${endSegment.toDate} ${endSegment.toTime}`,
        terminal: endSegment.to.terminal,
        country: endSegment.to.country,
      },
      duration: props.duration,
      airlines: _.uniqBy(
        segments.map((x) => ({ name: x.airline, code: x.airlineCode })),
        'name',
      ),
      techLanding: startSegment.techLandings,
      company: segments[0].operatingAirlineCode,
      companyName: segments[0].operatingAirline,
      active: props.active,
      flightNo: ``,
      stops: stops.map((x) => ({
        duration: x.duration,
        time: x.time,
        city: {
          ...x.city,
          terminal: '',
        },
      })),
    };

    return (
      <Container>
        <Content>
          {segments[0].flight_Type === FLIGHT_TYPES.LOWCOSTER && (
            <LowcosterPopup />
          )}
          <SegmentsContainer>
            {props.active ? (
              updateSegments.map((segment, key) => {
                return (
                  <SegmentInBooking
                    key={key}
                    fromTo={
                      key === 0
                        ? `${formatDirectionName(
                            flightIndex,
                            props.length,
                          )} ${fromCityName}-${toCityName}`
                        : undefined
                    }
                    baggageDescr={segment.luggageDescription}
                    flightType={flight_Type}
                    active={props.active}
                    transfer={stops[key]}
                    {...segment}
                  />
                );
              })
            ) : (
              <SegmentInBooking
                active={false}
                index={0}
                fareFamilyFeature={null}
                duration={detailProps.duration}
                flightNo={detailProps.flightNo}
                airplane={detailProps.airplane || ''}
                airline={
                  detailProps.airlines ? detailProps.airlines[0]?.name : ''
                }
                operatingAirlineCode={detailProps.company || ''}
                airlineCode={
                  detailProps.airlines ? detailProps.airlines[0]?.code : ''
                }
                from={detailProps.from}
                to={detailProps.to}
                fromDate={startSegment.fromDate}
                fromTime={startSegment.fromTime}
                id={'123'}
                toDate={endSegment.fromDate}
                toTime={endSegment.toTime}
                time={''}
                flightClass={''}
                seatMap={null}
                fareBasis={''}
                availSeats={0}
                classCode={''}
                fareFamily={null}
                operatingAirline={detailProps.companyName || ''}
                isLowcoster={false}
                flight_Type={startSegment.flight_Type}
                techLandings={detailProps.techLanding}
                transfer={stops[0]}
              />
            )}
          </SegmentsContainer>
        </Content>
        {!isMobile && separator && <StyledSeparator />}
        {isMobile && separator && <MobileSeparator />}
      </Container>
    );
  },
);

export default FlightItem;
